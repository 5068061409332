// src/helper/AuthStorage.js
import STORAGEKEY from "../config/APP/app.config";
import Cookie from "js-cookie";
import { appSettings } from "./appSettings";

class AuthStorage {
  static setStorageData(key, data) {
    Cookie.set(key, data, { domain: appSettings.AUTH_COOKIE_DOMAIN });
  }

  static setStorageJsonData(key, data) {
    Cookie.set(key, JSON.stringify(data), {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
  }

  static getStorageData(key) {
    return Cookie.get(key);
  }

  static getStorageJsonData(key) {
    const data = Cookie.get(key);
    if (data) return JSON.parse(data);
  }

  static getToken() {
    return Cookie.get(STORAGEKEY.token);
  }

  static setToken(value) {
    Cookie.set(STORAGEKEY.token, value, {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
  }

  static isUserAuthenticated() {
    return !!Cookie.get(STORAGEKEY.token);
  }

  static deauthenticateUser() {
    Cookie.remove(STORAGEKEY.token, { domain: appSettings.AUTH_COOKIE_DOMAIN });
    Cookie.remove(STORAGEKEY.refreshToken, {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
    Cookie.remove(STORAGEKEY.businessId, {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
    Cookie.remove(STORAGEKEY.userId, {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
    Cookie.remove(STORAGEKEY.userName, {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
    Cookie.remove(STORAGEKEY.userData, {
      domain: appSettings.AUTH_COOKIE_DOMAIN,
    });
    window.location = appSettings.AUTH_APP_URL; // or wherever your login page is
  }

  static deleteKey(key) {
    Cookie.remove(key);
  }

  static setCookie(key, data) {
    Cookie.set(key, data, { domain: appSettings.AUTH_COOKIE_DOMAIN });
  }

  /**
   * Decode the JWT token to retrieve the 'role' array.
   * Returns [] if token is missing or invalid.
   */
  static getUserRole() {
    const token = this.getToken();
    if (!token) {
      return [];
    }
    try {
      // 1. Split the token into [header, payload, signature], grab payload
      const base64Url = token.split(".")[1];
      if (!base64Url) return [];

      // 2. Convert base64Url to base64
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

      // 3. Decode from base64
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      // 4. Parse JSON
      const decodedPayload = JSON.parse(jsonPayload);

      // 5. Return the roles if found
      return Array.isArray(decodedPayload.role) ? decodedPayload.role : [];
    } catch (error) {
      console.error("Failed to decode token:", error);
      return [];
    }
  }
}

export default AuthStorage;
