import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import englishTranslation from "./locales/en/englishTranslation.en.json";

// Build local resources from the englishTranslation file.
const resources = {
  en: {
    translation: englishTranslation,
  },
};

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJEECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
  refLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
  // private: true,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    // Change fallbackLng to match your resources language code.
    fallbackLng: ["en"],
    resources,
    // Uncomment and adjust detection options if needed:
    // detection: {
    //   order: ['htmlTag', 'cookie', 'localStorage'],
    //   caches: ['cookie', 'localStorage']
    // },
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    // Uncomment this if you wish to save missing translations:
    // saveMissing: true
  });

export default i18n;
