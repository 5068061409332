// src/components/Sidebar/Sidebar.jsx (or wherever your file is)
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import campaignsWhite from "../../assets/img/campaigns-white-new.png";
import campaigns from "../../assets/img/campaigns-new.png";
import dashbordWhite from "../../assets/img/dashboard-white-new.png";
import dashbord from "../../assets/img/dashboard-new.png";
import customers from "../../assets/img/customers-new.png";
import customersWhite from "../../assets/img/customers-white-new.png";
import settings from "../../assets/img/settings-new.png";
import settingsWhite from "../../assets/img/settings-white-new.png";
import sidebarLogo from "../../assets/img/sidebar-logo.png";
import AuthStorage from "../../helper/AuthStorage";
import { useTranslation } from "react-i18next";
import Avtar from "../../assets/img/avtar.png";
import signOut from "../../assets/img/sign-out.png";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessDetails } from "redux/features/business/businessSlice";
import { getUserDataAPI } from "redux/features/authentication/authSlice";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import moment from "moment";
import { Button } from "react-bootstrap";
import Divider from "@mui/material/Divider";
import addsquare from "../../assets/img/addsquare.png";
import questions from "../../assets/img/helpIcon.png";
import person from "../../assets/img/profile.png";
import game from "../../assets/img/game.png";
import profileColor from "../../assets/img/profileColor.png";
import gameColor from "../../assets/img/gameColor.png";
import logoSide from "../../assets/img/side-logo-small.png";
import ArrowDownColored from "../../assets/img/ArrowDownColored.png";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowY: "visible !important",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "visible !important",
  overflowY: "visible !important",
  width: "82px", // Default width
  "@media screen and (max-width: 768px)": {
    width: "56px",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ showSidebar, setSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // For storing user data after fetch
  const [userData, setUserData] = useState("");

  // Get userRole from the token
  const userRole = AuthStorage.getUserRole() || [];
  const isBSUP = userRole.includes("BSUP"); // Determines if the user has BSUP role

  // Define the sidebar items
  let sidebarBtn = [
    {
      img: dashbord,
      img1: dashbordWhite,
      text: "Dashboard",
      path: "/dashboard",
    },
    {
      img: campaigns,
      img1: campaignsWhite,
      text: "Campaigns",
      path: "/campaigns",
    },
    {
      img: customers,
      img1: customersWhite,
      text: "Customers",
      path: "/customers",
    },
    // Additional items can be added here...
  ];

  // If user has "BSUP" role, only show Dashboard & Campaigns
  if (isBSUP) {
    sidebarBtn = sidebarBtn.filter(
      (item) => item.text === "Dashboard" || item.text === "Campaigns"
    );
  }

  // Bottom section items (Profile, etc.)
  const sidebarBtnBottom = [
    {
      img: profileColor,
      img1: person,
      text: "Profile",
      path: "/editprofile",
    },
  ];

  // Redux calls to retrieve data
  const businessState = useSelector((state) => state.businessState.data);
  const setUserDashboardData = async () => {
    await dispatch(getBusinessDetails()).then(unwrapResult);
    await dispatch(getUserDataAPI())
      .then(unwrapResult)
      .then((result) => {
        setUserData(result?.response);
      });
  };

  // On mount, fetch user/business data
  useEffect(() => {
    setUserDashboardData();
    // eslint-disable-next-line
  }, []);

  // Toggling the drawer open/closed
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  // Sign out action
  const signout = () => {
    AuthStorage.deauthenticateUser();
  };

  // “Start New Campaign” button
  const handleStartNewCampaignClick = () => {
    window.location.href = `/campaigns/boost-product`;
  };

  return (
    <div className="sidebar-side">
      <Drawer
        variant="permanent"
        open={open}
        style={{ zIndex: "1", width: "82px" }}
        sx={{
          "& .MuiDrawer-paper": {
            border: "none !important",
            boxShadow: "4px 20px 40px 0px rgba(0, 0, 0, 0.10) !important",
          },
        }}
      >
        <DrawerHeader
          style={{
            justifyContent: open ? "space-between" : "center",
          }}
        >
          {open ? (
            <>
              <img src={sidebarLogo} alt="logo" className="sidebar-logo" />
              <div className="drawer-Pin" onClick={handleDrawerClose}>
                <img
                  src={ArrowDownColored}
                  className={open ? "drawer_pin_open" : "drawer_pin"}
                  alt="toggle"
                />
              </div>
            </>
          ) : (
            <div
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={handleDrawerClose}
            >
              <img src={logoSide} className="sidebar-logo-small" alt="logo" />
            </div>
          )}
        </DrawerHeader>

        <List style={{ height: "100%", overflowY: "scroll" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              className="sidebar-links"
              style={{
                margin: !open && 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* Start New Campaign button (top) */}
              {!isBSUP &&
                (open ? (
                  <div
                    style={{
                      display: "flex",
                      margin: "27px 0px",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className="outline_btn_dark"
                      onClick={handleStartNewCampaignClick}
                    >
                      <span>Start New Campaign</span>
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      margin: "27px 0px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleStartNewCampaignClick}
                  >
                    <img
                      style={{
                        cursor: "pointer",
                        width: "44px",
                        height: "44px",
                      }}
                      src={addsquare}
                      alt="add"
                    />
                  </div>
                ))}

              {/* Top Sidebar Buttons */}
              {sidebarBtn.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={() => navigate(item.path)}
                    className={`${
                      location.pathname.includes(item.path)
                        ? "active boomio-bold purple-black"
                        : ""
                    } disabel`}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 0,
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          location.pathname.includes(item.path)
                            ? item.img
                            : item.img1
                        }
                        alt={item.text}
                        className="icon-size"
                      />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={item.text}
                        sx={{
                          opacity: open ? 1 : 0,
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}

              {/* Divider or spacing */}
              {open ? (
                <div style={{ width: "100%", margin: "15px 0px" }} />
              ) : (
                <div
                  style={{ margin: "15px 0px" }}
                  className="sidebar-border"
                />
              )}

              {/* Bottom Sidebar Buttons (e.g. Profile) */}
              {!isBSUP &&
                sidebarBtnBottom.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => navigate(item.path)}
                      className={`${
                        location.pathname.includes(item.path)
                          ? "active boomio-bold purple-black"
                          : ""
                      } disabel`}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 0,
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            location.pathname.includes(item.path)
                              ? item.img
                              : item.img1
                          }
                          alt={item.text}
                          className="icon-size"
                        />
                      </ListItemIcon>
                      {open && (
                        <ListItemText
                          primary={item.text}
                          sx={{
                            opacity: open ? 1 : 0,
                            margin: 0,
                            padding: 0,
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
            </div>

            {/* Sign Out button at bottom */}
            <div
              className="sidebar-links"
              style={{ border: "none", marginBottom: "15px" }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={signout}
                  className="disabel"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 0,
                      justifyContent: "center",
                    }}
                  >
                    <img src={signOut} alt="sign-out" className="icon-size" />
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={t("Header.DropDown.signOut")}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </div>
          </div>
        </List>

        {open && (
          <footer className="copyright">
            <p>
              Copyrights {moment().format("YYYY")} <span>Boomio</span>
              <br />
              All Rights Reserved.
            </p>
          </footer>
        )}
      </Drawer>
    </div>
  );
};

export default Sidebar;
