import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import AuthStorage from "../helper/AuthStorage";
import AuthLayout from "../layout/AuthLayout";
// import all necessary pages:
import Layout from "../layout/Layout";
import AutomaticCampaign from "./campaigns/automaticCampaign/AutomaticCampaign";
import AutomaticCampaignOutlet from "./campaigns/automaticCampaign/AutomaticCampaignOutlet";
import GetFeedback from "./campaigns/automaticCampaign/GetFeedback";
import VisitMyShop from "./campaigns/automaticCampaign/VisitMyShop";
import Campaigns from "./campaigns/Campaigns";
import CampaignsOutlet from "./campaigns/CampaignsOutlet";
import EventOrganization from "./campaigns/eventOrganization/EventOrganization";
import ProductDesign from "./campaigns/promotionalCampaign/ProductDesign";
import StartPromotionalCampaign from "./campaigns/promotionalCampaign/StartPromotionalCampaign";
import StartPromotionalCampaignOutlet from "./campaigns/promotionalCampaign/StartPromotionalCampaignOutlet";
import StartCampaign from "./campaigns/StartCampaign";
import StartCampaignOutlet from "./campaigns/startCampaign/StartCampaignOutlet";
import Coupons from "./coupons/Coupons";
import Customers from "./customers/Customers";
import MobileApp from "./mobileApp/MobileApp";
import Dashboard from "./dashboard/DashboardNew";
import EditProfile from "./settings/EditProfile";
import WelcomeReward from "./campaigns/welcomeReward/WelcomeReward";
import StartFirstCampaign from "./campaigns/StartFirstCampaign";
import SpecificProductReward from "./campaigns/specificProductReward/SpecificProductReward";
import LeadToPurchase from "./campaigns/leadToPurchase/LeadToPurchase";
import IncreaseBrowsingTime from "./campaigns/IncreaseBrowsingTime/IncreaseBrowsingTime";
import Reward from "./campaigns/newCustomerCampaign/Reward";
import NewCustomerCampaign from "./campaigns/NewCustomerCampaign";
import BoostProduct from "./campaigns/boostProduct/BoostProduct";
import Support from "./support/Support";
import GetCouponCode from "./getCouponCode/getCouponCode";
import Notifications from "./notifications/Notifications";
import Teaser from "./teser/Teaser";
import TeaserOutlet from "./teser/TeaserOutlet";
import AddTeaser from "./teser/AddTeaser";
import ConnectYourEStore from "./connectyourstore/ConnectYourEStore";
import ErrorPage from "./errorPage/ErrorPage";

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get user role from storage (ensure this method exists in AuthStorage)
  const userRole = AuthStorage.getUserRole() || [];
  // e.g. returns ["BSUP"] or an empty array

  // Layout paths that do not require user authentication
  const forLayout = [
    "/signup",
    "/",
    "/forgot-password",
    "/reset-password",
    "/getCouponCode",
  ];

  // Paths that require the authenticated layout
  const forAuthLayout = [
    "/campaigns",
    "/start-campaign",
    "/coupons",
    "/dashboard",
    "/mobile-app",
    "/customers",
    "/start-promotional-campaign",
    "/product-design",
    "/automatic-campaign",
    "/first-timer-reward",
    "/visit-my-shop",
    "/get-feedback",
    "/event-organization",
    "/notifications",
    "/teaser",
    "/connect-your-e-store",
  ];

  // If user is already logged in, do not let them visit the forLayout routes
  useEffect(() => {
    if (AuthStorage.isUserAuthenticated()) {
      if (forLayout.includes(location.pathname)) {
        navigate("/campaigns");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AuthLayout>
      <Routes>
        {/* Redirect root path to dashboard */}
        <Route path="/" element={<Navigate replace to="/dashboard" />} />

        {/**
         * If user has "BSUP" role:
         *   - Show only /dashboard and /customers
         *   - Everything else redirects to /dashboard
         */}
        {userRole.includes("BSUP") ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/Campaigns" element={<Campaigns />} />
            {/* If BSUP user tries any other path, redirect to dashboard */}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          /**
           * Otherwise (non-BSUP):
           *   - Show the full route set
           */
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/mobile-app" element={<MobileApp />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/coupons" element={<Coupons />} />
            <Route path="/support" element={<Support />} />
            <Route path="/editprofile" element={<EditProfile />} />

            {/* Campaigns */}
            <Route path="/campaigns" element={<CampaignsOutlet />}>
              <Route path="" element={<Campaigns />} />
              <Route path="start-campaign" element={<StartCampaignOutlet />}>
                <Route path="" element={<StartCampaign />} />
                <Route
                  path="automatic-campaign"
                  element={<AutomaticCampaignOutlet />}
                >
                  <Route path="" element={<AutomaticCampaign />} />
                  <Route
                    path="first-timer-reward"
                    element={<ProductDesign type={"CARD_PAYMENTS"} />}
                  />
                  <Route path="welcome-reward" element={<WelcomeReward />} />
                  <Route path="visit-my-shop" element={<VisitMyShop />} />
                  <Route path="get-feedback" element={<GetFeedback />} />
                </Route>
                <Route
                  path="event-organization"
                  element={<EventOrganization />}
                />
                <Route
                  path="start-promotional-campaign"
                  element={<StartPromotionalCampaignOutlet />}
                >
                  <Route path="" element={<StartPromotionalCampaign />} />
                  <Route
                    path="product-design"
                    element={<ProductDesign type={"CODE_SCAN"} />}
                  />
                </Route>
              </Route>

              <Route path="boost-product" element={<BoostProduct />} />

              {/* Start First Campaign */}
              <Route
                path="start-first-campaign"
                element={<StartCampaignOutlet />}
              >
                <Route path="" element={<StartFirstCampaign />} />
                <Route
                  path="automatic-campaign"
                  element={<AutomaticCampaignOutlet />}
                >
                  <Route path="" element={<AutomaticCampaign />} />
                  <Route
                    path="first-timer-reward"
                    element={<ProductDesign type={"CARD_PAYMENTS"} />}
                  />
                  <Route
                    path="specific-reward"
                    element={<SpecificProductReward />}
                  />
                  <Route path="lead-to-purchase" element={<LeadToPurchase />} />
                  <Route
                    path="increase-browsing-time"
                    element={<IncreaseBrowsingTime />}
                  />
                  <Route path="visit-my-shop" element={<VisitMyShop />} />
                  <Route path="get-feedback" element={<GetFeedback />} />
                </Route>
                <Route
                  path="event-organization"
                  element={<EventOrganization />}
                />
                <Route
                  path="start-promotional-campaign"
                  element={<StartPromotionalCampaignOutlet />}
                >
                  <Route path="" element={<StartPromotionalCampaign />} />
                  <Route
                    path="product-design"
                    element={<ProductDesign type={"CODE_SCAN"} />}
                  />
                </Route>
              </Route>

              {/* New Customers Campaign */}
              <Route
                path="new-customers-campaign"
                element={<StartCampaignOutlet />}
              >
                <Route path="" element={<NewCustomerCampaign />} />
                <Route
                  path="automatic-campaign"
                  element={<AutomaticCampaignOutlet />}
                >
                  <Route path="" element={<AutomaticCampaign />} />
                  <Route
                    path="first-timer-reward"
                    element={<ProductDesign type={"CARD_PAYMENTS"} />}
                  />
                  <Route path="reward" element={<Reward />} />
                  <Route path="visit-my-shop" element={<VisitMyShop />} />
                  <Route path="get-feedback" element={<GetFeedback />} />
                </Route>
                <Route
                  path="event-organization"
                  element={<EventOrganization />}
                />
                <Route
                  path="start-promotional-campaign"
                  element={<StartPromotionalCampaignOutlet />}
                >
                  <Route path="" element={<StartPromotionalCampaign />} />
                  <Route
                    path="product-design"
                    element={<ProductDesign type={"CODE_SCAN"} />}
                  />
                </Route>
              </Route>
            </Route>

            {/* Notifications, Coupons, Teaser, etc. */}
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/coupons" element={<Coupons />} />
            <Route path="/teaser" element={<TeaserOutlet />}>
              <Route path="" element={<Teaser />} />
              <Route path="add" element={<AddTeaser />} />
            </Route>
            <Route
              path="/connect-your-e-store"
              element={<ConnectYourEStore />}
            />
            <Route path="/*" element={<ErrorPage />} />
          </>
        )}
      </Routes>
    </AuthLayout>
  );
};

export default Index;
